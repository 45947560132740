import * as React from "react";
import { heroTextType } from "../../assets/Types/AcfGroupTypes/HeroText.type";
import styles from "./HeroTextContainer.module.scss";

type HeroTextContainerProps = {
  heroProps: heroTextType;
  sectionClassname?: string | null;
  contClassname?: string | null;
};

const HeroTextContainer: React.FC<HeroTextContainerProps> = ({
  heroProps,
  sectionClassname,
  contClassname,
}) => {
  return (
    <section className={`${sectionClassname} ${styles.heroText}`}>
      <div className={`${contClassname}`}>
        <h1>{heroProps.title}</h1>
        <p>{heroProps.subCopy}</p>
      </div>
    </section>
  );
};

export default HeroTextContainer;
