import * as React from "react";
import { graphql, PageProps } from "gatsby";
import { writeForUsType } from "../../assets/Types/AcfGroupTypes/WriteForUs.type";
import HeroTextContainer from "../../containers/HeroTextContainer/HeroTextContainer";
import WriteUsListContainer from "../../containers/WriteUsListContainer/WriteUsListContainer";
import { YoastSEOType } from "../../assets/Types";
import SEO from "gatsby-plugin-wpgraphql-seo";
import DefaultPollenLayout from "../../layouts/DefaultPollenLayout";
import { deIndexSeo } from "../../utils/deIndexSeo";

type WriteForUsProps = {
  wpPage: {
    seo: YoastSEOType;
    template: {
      writeForUsPg: {
        writeForUsTitle: string;
        writeForUsSubcopy: string;
        writeForUsList: writeForUsType[];
      };
    };
  };
};

const WriteForUsPage: React.FC<PageProps<WriteForUsProps>> = ({
  data,
  location,
}) => {
  const { wpPage } = data;
  const {
    template: {
      writeForUsPg: { writeForUsTitle, writeForUsSubcopy, writeForUsList },
    },
  } = wpPage;

  const heroProps = { title: writeForUsTitle, subCopy: writeForUsSubcopy };

  return (
    <DefaultPollenLayout noFooter noBanner pathname={location.pathname}>
      {wpPage.seo && (
        <SEO post={deIndexSeo(wpPage as unknown as Queries.WpPage)} />
      )}
      <HeroTextContainer
        heroProps={heroProps}
        contClassname={`full-width write_container pad-top--large-sans-header pad-bottom--large styled-content pollen-content`}
      />
      <WriteUsListContainer writeForUsList={writeForUsList} />
    </DefaultPollenLayout>
  );
};

export default WriteForUsPage;

export const query = graphql`
  query WriteForUsQuery($id: String) {
    wpPage(id: { eq: $id }) {
      id
      ...YoastSEOFragment
      template {
        ... on WpWriteForUsTemplate {
          templateName
          writeForUsPg {
            writeForUsTitle
            writeForUsSubcopy
            writeForUsList {
              label
              buttonText
              buttonLink
              wysiwyg
            }
          }
        }
      }
    }
  }
`;
