import * as React from "react";
import { writeForUsType } from "../../assets/Types/AcfGroupTypes/WriteForUs.type";
import PrimaryButtonWithArrow from "../../components/Buttons/PrimaryButtonWithArrow/PrimaryButtonWithArrow";

import styles from "./WriteUsListContainer.module.scss";

type HeroTextContainerProps = {
  writeForUsList: writeForUsType[];
  sectionClassname?: string | null;
  contClassname?: string | null;
};

const HeroTextContainer: React.FC<HeroTextContainerProps> = ({
  writeForUsList,
  sectionClassname,
  contClassname,
}) => {
  return (
    <section className={`${sectionClassname}`}>
      <div className={`${contClassname}`}>
        {writeForUsList.map((item, index) => (
          <div key={index} className={`${styles.writeUsContainer}`}>
            <div className={`full-width ${styles.writeListGrid}`}>
              <h2 className={styles.titleW}>
                <span
                  className={styles.title}
                  dangerouslySetInnerHTML={{
                    __html: item.label,
                  }}
                />
              </h2>
              <div>
                <div
                  className={styles.content}
                  dangerouslySetInnerHTML={{
                    __html: item.wysiwyg,
                  }}
                />
                {item.buttonText && (
                  <PrimaryButtonWithArrow
                    buttonText={item.buttonText}
                    buttonLink={item.buttonLink}
                    className={styles.buttonLink}
                    mailTo={true}
                  />
                )}
              </div>
            </div>
          </div>
        ))}
      </div>
    </section>
  );
};

export default HeroTextContainer;
